import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";

// Styles
import Styles from "./TruckFormFields.module.scss";

// Components
import QuickFilterPillGroup from "@vahak/core/dist/components/quick-filter-pill-group/QuickFilterPillGroup";

// Types
import {
    commonLorryFormFieldNames,
    editBulkLoadLorryDetails,
    loadCreationFormFieldsTypes,
    lorryCreationFormFieldsTypes
} from "../../../helpers/constants";
import { GA4EventNames, LoadPostingFormEvents } from "@vahak/core/dist/constants/event-names";
import { NEW_LORRY_TYPES, LORRY_BODY_TYPE } from "@vahak/core/dist/constants/lorryTypes";
import { IDs } from "@vahak/core/dist/constants/automation";

// Helpers
import { scrollToElement } from "@vahak/core/dist/methods/scrollToElement";
import { truckTyreClassification } from "./truckClassificationHelper";
import UploadRc from "../upload-rc/UploadRc";
import Typography from "@vahak/core-ui/dist/components/Typography";
import { useEventTrackerService } from "@vahak/core/dist/_services/hooks/useEventTrackerService";
import CapacityField from "../capacity-field/CapacityField";
import { GenericObject } from "@vahak/core/dist/custom-types/generic-object";

interface TruckFormFieldsProps {
    formik: FormikProps<loadCreationFormFieldsTypes | lorryCreationFormFieldsTypes | editBulkLoadLorryDetails>;
    yesThisIsMyVehicle?: boolean;
    isLorryPosting: boolean;
    trackLoadFormEvent?: (name: string, data: GenericObject) => void;
    showUploadRc?: boolean;
}

export const TruckBodyOptions = [
    { id: LORRY_BODY_TYPE.OPEN, value: "Open Half Body" },
    { id: LORRY_BODY_TYPE.CLOSED, value: "Open Full Body" }
];

const sizeOptions = [
    { id: 17, value: "17 ft" },
    { id: 19, value: "19 ft" },
    { id: 20, value: "20 ft" },
    { id: 22, value: "22 ft" }
];

/**
 * This is important when we create lorry with api data
 * to filter tyre list based on capacity
 */

const tyreCapacityMapping = {
    0: {
        start: 0,
        end: -1 // Empty Array will be created
    },
    6: {
        start: 7,
        end: 10
    },
    10: {
        start: 11,
        end: 16
    },
    12: {
        start: 17,
        end: 25
    },
    14: {
        start: 26,
        end: 30
    },
    18: {
        start: 31,
        end: 40
    },
    22: {
        start: 41,
        end: 50
    }
};

const TruckFormFields = ({
    formik,
    yesThisIsMyVehicle,
    isLorryPosting,
    trackLoadFormEvent,
    showUploadRc
}: TruckFormFieldsProps) => {
    const { t } = useTranslation(["common"]);

    const [isBodyTypeSelected, setIsBodyTypeSelected] = useState(false);
    const [isTyreCountSelected, setIsTyreCountSelected] = useState(false);
    const [isLorrySizeSelected, setIsLorrySizeSelected] = useState(false);

    const bodyTypeDivRef = useRef<HTMLDivElement>(null);
    const tyresDivRef = useRef<HTMLDivElement>(null);
    const sizeDivRef = useRef<HTMLDivElement>(null);
    const capacityDivRef = useRef<HTMLDivElement>(null);
    const rcUploadDivRef = useRef<HTMLDivElement>(null);

    const { values: lorryFormikValues } = formik as FormikProps<lorryCreationFormFieldsTypes>;
    const { values: loadFormikValues } = formik as FormikProps<loadCreationFormFieldsTypes>;

    const selectedLorryType = NEW_LORRY_TYPES.filter((x) => x.id === formik.values.lorryType)[0];
    const isEditModal = lorryFormikValues?.formType === "edit";

    //Events
    const { sendGAandMoEngageEvent } = useEventTrackerService();

    const handleBodyTypeFilterChange = (value: number) => {
        // Resetting the after formik state
        formik.setValues({
            ...formik.values,
            [commonLorryFormFieldNames.lorryBodyType]: value,
            [commonLorryFormFieldNames.lorryTyres]: 0,
            [commonLorryFormFieldNames.lorrySize]: 0
        });
        setIsBodyTypeSelected(true);
        setIsTyreCountSelected(false);
        setIsLorrySizeSelected(false);

        scrollToElement(tyresDivRef);
        /**
         * Events
         */

        if (isLorryPosting) {
            sendGAandMoEngageEvent({
                name: GA4EventNames.LORRY_POSTING_MODAL.lorry_form_body_type_selection,
                data: {
                    vehicle_number: lorryFormikValues.lorryNum,
                    lorry_type: lorryFormikValues.lorryType,
                    current_city: lorryFormikValues?.currentCityText?.split(",")[0],
                    total_route: lorryFormikValues.routeIds.length,
                    routes: lorryFormikValues.routeIds,
                    vehicle_type: NEW_LORRY_TYPES.find((val) => val.id === lorryFormikValues.lorryType)?.label,
                    capacity: lorryFormikValues.lorryCapacity,
                    body_type: TruckBodyOptions.find((x) => x.id === value)?.value
                }
            });
        } else {
            trackLoadFormEvent?.(LoadPostingFormEvents["load_form_body_type_selection"], {
                body_type: TruckBodyOptions.find((x) => x.id === value)
                    ?.value?.toLowerCase()
                    .replaceAll(" ", "_")
            });
        }
    };

    const handleTyreSelection = (value: number) => {
        // Resetting the after formik state

        let obj = {
            [commonLorryFormFieldNames.lorryTyres]: value,
            [commonLorryFormFieldNames.lorrySize]: 0
        };

        formik.setValues({
            ...formik.values,
            ...obj
        });
        setIsTyreCountSelected(true);
        setIsLorrySizeSelected(false);
        scrollToElement(sizeDivRef);
        scrollToElement(capacityDivRef);
        scrollToElement(rcUploadDivRef);

        /**
         * Events
         */
        if (isLorryPosting) {
            sendGAandMoEngageEvent({
                name: GA4EventNames.LORRY_POSTING_MODAL.lorry_form_tyres_selection,
                data: {
                    vehicle_number: lorryFormikValues?.lorryNum,
                    lorry_type: lorryFormikValues?.lorryType,
                    current_city: lorryFormikValues?.currentCityText?.split(",")[0],
                    total_route: lorryFormikValues?.routeIds?.length,
                    routes: lorryFormikValues?.routeIds,
                    vehicle_type: NEW_LORRY_TYPES.find((val) => val.id === lorryFormikValues?.lorryType)?.label,
                    capacity: lorryFormikValues?.lorryCapacity,
                    body_type: TruckBodyOptions.find((x) => x.id === lorryFormikValues.lorryBodyType)?.value,
                    total_tyres: value
                }
            });
        } else {
            trackLoadFormEvent?.(LoadPostingFormEvents["load_form_tyres_selection"], {
                required_tyres: value
            });
        }
    };

    const handleLorrySizeChange = (value: number) => {
        // Resetting the after formik state
        formik.setValues({
            ...formik.values,
            [commonLorryFormFieldNames.lorrySize]: value,
            [commonLorryFormFieldNames.sizeText]: "ft"
        });
        setIsLorrySizeSelected(true);

        formik.values.lorryCapacity ? scrollToElement(rcUploadDivRef) : scrollToElement(capacityDivRef);
    };

    const isBodyTypeSelectionAvailable = true;
    const isTyreSelectionAvailable = isBodyTypeSelected;
    const isSizeSelectionAvailable = formik.values.lorryTyres <= 6 && isBodyTypeSelected && isTyreCountSelected;
    const isLorryCapacitySelectionAvailable =
        formik.values.showCapacityField &&
        isBodyTypeSelected &&
        isTyreCountSelected &&
        (isLorrySizeSelected || formik.values.lorryTyres > 6) &&
        formik.values.isLorryCapacityRequired;

    const isRCUploadAvailable =
        isLorryPosting &&
        Boolean(formik.values.lorryCapacity) &&
        (showUploadRc || !yesThisIsMyVehicle) &&
        !formik.errors.lorryCapacity &&
        isBodyTypeSelected &&
        isTyreCountSelected &&
        (isLorrySizeSelected || formik.values.lorryTyres > 6) &&
        !isEditModal;
    useEffect(() => {
        if (formik.values.lorryBodyType === 0 || formik.values.lorryBodyType === 1) setIsBodyTypeSelected(true);
        else setIsBodyTypeSelected(false);
        if (formik.values.lorryTyres) setIsTyreCountSelected(true);
        else setIsTyreCountSelected(false);
        if (formik.values.lorrySize) setIsLorrySizeSelected(true);
        else setIsLorrySizeSelected(false);
    }, [formik.values.lorryBodyType, formik.values.lorryTyres, formik.values.lorrySize, formik.values.lorryType]);

    /**
     * Classification based on rc data
     * tyresOptions - is without classification (list of all tyre options)
     * in case of manual posting we gonna get the capacity at the last we'll be showing all the tyre options without classification
     */
    // let availableTyreOptions = selectedLorryType.tyreWithCapacity;

    const lorryCapacity = formik.values.lorryCapacity;

    //Need to simplify this condition
    let availableTyreOptions =
        selectedLorryType.tyreWithCapacity &&
        truckTyreClassification(selectedLorryType.tyreWithCapacity, tyreCapacityMapping, lorryCapacity);

    /**
     * Capacity based on tyres
     */
    const selectedLorryTyreAndCapacity = selectedLorryType.tyreWithCapacity?.find(
        (x) => x.id === formik.values.lorryTyres
    );
    useEffect(() => {
        scrollToElement(bodyTypeDivRef);
    }, []);

    return (
        <div>
            {isBodyTypeSelectionAvailable && (
                <div ref={bodyTypeDivRef}>
                    <div className={Styles.sectionHeading}>
                        <Typography mSize="sm" required weight="semibold">
                            {t("lorryPosting.select_truck_body")}
                        </Typography>
                    </div>
                    <QuickFilterPillGroup
                        options={TruckBodyOptions}
                        selected={formik.values.lorryBodyType}
                        onFilterChange={handleBodyTypeFilterChange}
                        id={IDs.postingForm.selectType.body}
                    />
                </div>
            )}
            {isTyreSelectionAvailable && availableTyreOptions && (
                <div ref={tyresDivRef}>
                    <div className={Styles.sectionHeading}>
                        <Typography mSize="sm" required weight="semibold">
                            {t("lorryPosting.select_tyre")}
                        </Typography>
                    </div>
                    <QuickFilterPillGroup
                        options={availableTyreOptions}
                        selected={formik.values.lorryTyres}
                        onFilterChange={handleTyreSelection}
                        id={IDs.postingForm.selectType.tyres}
                    />
                </div>
            )}
            {isSizeSelectionAvailable && (
                <div ref={sizeDivRef}>
                    <div className={Styles.sectionHeading}>
                        <Typography mSize="sm" required weight="semibold">
                            {t("lorryPosting.select_truck_feet")}
                        </Typography>
                    </div>
                    <QuickFilterPillGroup
                        options={sizeOptions}
                        selected={formik.values.lorrySize}
                        onFilterChange={handleLorrySizeChange}
                        id={IDs.postingForm.selectType.feet}
                    />
                </div>
            )}
            {isRCUploadAvailable ? (
                <div ref={rcUploadDivRef}>
                    <UploadRc formik={formik} />{" "}
                </div>
            ) : null}
        </div>
    );
};

export default TruckFormFields;
