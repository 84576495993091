import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";

// Styles
import Styles from "./LcvFormFields.module.scss";

// Components
import QuickFilterPillGroup from "@vahak/core/dist/components/quick-filter-pill-group/QuickFilterPillGroup";

// Helpers
import {
    commonLorryFormFieldNames,
    editBulkLoadLorryDetails,
    initialLorryFieldFormValues,
    loadCreationFormFieldsTypes,
    lorryCreationFormFieldsTypes
} from "../../../helpers/constants";
import { GA4EventNames, LoadPostingFormEvents } from "@vahak/core/dist/constants/event-names";
import { LORRY_BODY_TYPE, NEW_LORRY_TYPES } from "@vahak/core/dist/constants/lorryTypes";
import { IDs } from "@vahak/core/dist/constants/automation";

import UploadRc from "../upload-rc/UploadRc";
import { useEffect, useRef, useState } from "react";
import { scrollToElement } from "@vahak/core/dist/methods/scrollToElement";
import Typography from "@vahak/core-ui/dist/components/Typography";
import { getLcvSizeEnum, lcvBodyClassification } from "./lcvClassificationHelper";
import { useEventTrackerService } from "@vahak/core/dist/_services/hooks/useEventTrackerService";
import { GenericObject } from "@vahak/core/dist/custom-types/generic-object";
import { TruckBodyOptions } from "../truck/TruckFormFields";

interface LcvFormFieldsProps {
    formik: FormikProps<lorryCreationFormFieldsTypes | loadCreationFormFieldsTypes | editBulkLoadLorryDetails>;
    yesThisIsMyVehicle?: boolean;
    isLorryPosting: boolean;
    trackLoadFormEvent?: (name: string, data: GenericObject) => void;
    showUploadRc?: boolean;
}

export const LcvBodyOptions = [
    { id: LORRY_BODY_TYPE.OPEN_BODY, value: "Open Body" },
    { id: LORRY_BODY_TYPE.CLOSE_BODY, value: "Closed Body" }
];

export enum lcvSizeOptionsEnum {
    FT_8 = 1,
    FT_10,
    FT_14,
    FT_17,
    FT_19,
    FT_20,
    FT_22
}

const lcvSizeOptions = [
    { id: lcvSizeOptionsEnum.FT_8, value: "8 ft" },
    { id: lcvSizeOptionsEnum.FT_10, value: "10 ft" },
    { id: lcvSizeOptionsEnum.FT_14, value: "14 ft" },
    { id: lcvSizeOptionsEnum.FT_17, value: "17 ft" },
    { id: lcvSizeOptionsEnum.FT_19, value: "19 ft" },
    { id: lcvSizeOptionsEnum.FT_20, value: "20 ft" },
    { id: lcvSizeOptionsEnum.FT_22, value: "22 ft" }
];

const tyreCapacityMapping = {
    0: {
        start: 0,
        end: -1 // Empty Array will be created
    },
    3: {
        start: 0.3,
        end: 3
    },
    4: {
        start: 1,
        end: 10
    }
};

const sizeCapacityMapping = {
    0: {
        start: 0,
        end: -1 // For Empty Array
    },
    [lcvSizeOptionsEnum.FT_8]: {
        start: 1,
        end: 1
    },
    [lcvSizeOptionsEnum.FT_14]: {
        start: 2,
        end: 10
    }
};

const LcvFormFields = ({
    formik,
    yesThisIsMyVehicle,
    isLorryPosting,
    trackLoadFormEvent,
    showUploadRc
}: LcvFormFieldsProps) => {
    const { t } = useTranslation(["common"]);

    const [selectedSize, setSelectedSize] = useState<lcvSizeOptionsEnum | null>(
        formik.values.sizeText !== "" ? getLcvSizeEnum(formik.values.lorrySize) : null
    );
    const [isBodyTypeSelected, setIsBodyTypeSelected] = useState(false);

    const { values: lorryFormikValues } = formik as FormikProps<lorryCreationFormFieldsTypes>;
    const { values: loadFormikValues } = formik as FormikProps<loadCreationFormFieldsTypes>;

    const bodyTypeDivRef = useRef<HTMLDivElement>(null);
    const tyresDivRef = useRef<HTMLDivElement>(null);
    const sizeSelectionDivRef = useRef<HTMLDivElement>(null);
    const capacityDivRef = useRef<HTMLDivElement>(null);
    const rcUploadDivRef = useRef<HTMLDivElement>(null);
    const isEditModal = lorryFormikValues?.formType === "edit";

    //Events
    const { sendGAandMoEngageEvent } = useEventTrackerService();

    const handleBodyTypeFilterChange = (value: number) => {
        // Resetting the after formik state
        formik.setValues({
            ...formik.values,
            [commonLorryFormFieldNames.lorryBodyType]: value,
            [commonLorryFormFieldNames.sizeText]: initialLorryFieldFormValues.sizeText,
            [commonLorryFormFieldNames.lorrySize]: initialLorryFieldFormValues.lorrySize
        });
        setSelectedSize(null);
        setIsBodyTypeSelected(true);

        scrollToElement(sizeSelectionDivRef);
        /**
         * Events
         */

        if (isLorryPosting) {
            sendGAandMoEngageEvent({
                name: GA4EventNames.LORRY_POSTING_MODAL.lorry_form_body_type_selection,
                data: {
                    vehicle_number: lorryFormikValues.lorryNum,
                    lorry_type: lorryFormikValues.lorryType,
                    current_city: lorryFormikValues?.currentCityText?.split(",")[0],
                    total_route: lorryFormikValues.routeIds.length,
                    routes: lorryFormikValues.routeIds,
                    vehicle_type: NEW_LORRY_TYPES.find((val) => val.id === lorryFormikValues.lorryType)?.label,
                    capacity: lorryFormikValues.lorryCapacity,
                    body_type: LcvBodyOptions.find((x) => x.id === value)?.value
                }
            });
        } else {
            trackLoadFormEvent?.(LoadPostingFormEvents["load_form_body_type_selection"], {
                body_type: LcvBodyOptions.find((x) => x.id === value)
                    ?.value?.toLowerCase()
                    .replaceAll(" ", "_")
            });
        }
    };

    const handleTyreSelection = (value: number) => {
        // Resetting the after formik state

        let obj = {
            [commonLorryFormFieldNames.lorryTyres]: value,
            [commonLorryFormFieldNames.sizeText]: initialLorryFieldFormValues.sizeText,
            [commonLorryFormFieldNames.lorrySize]: initialLorryFieldFormValues.lorrySize
        };
        setSelectedSize(null);
        formik.setValues({
            ...formik.values,
            ...obj
        });
        scrollToElement(sizeSelectionDivRef);
        scrollToElement(capacityDivRef);
        scrollToElement(rcUploadDivRef);

        /**
         * Events
         */
        if (isLorryPosting) {
            sendGAandMoEngageEvent({
                name: GA4EventNames.LORRY_POSTING_MODAL.lorry_form_tyres_selection,
                data: {
                    vehicle_number: lorryFormikValues.lorryNum,
                    lorry_type: lorryFormikValues.lorryType,
                    current_city: lorryFormikValues?.currentCityText?.split(",")[0],
                    total_route: lorryFormikValues.routeIds.length,
                    routes: lorryFormikValues.routeIds,
                    vehicle_type: NEW_LORRY_TYPES.find((val) => val.id === lorryFormikValues.lorryType)?.label,
                    capacity: lorryFormikValues.lorryCapacity,
                    body_type: LcvBodyOptions.find((x) => x.id === lorryFormikValues.lorryBodyType)?.value,
                    total_tyres: value
                }
            });
        } else {
            trackLoadFormEvent?.(LoadPostingFormEvents["load_form_tyres_selection"], {
                required_tyres: value
            });
        }
    };

    const handleSizeChange = (id: lcvSizeOptionsEnum) => {
        setSelectedSize(id);

        let size = 0;
        const selectedSizeOption = lcvSizeOptions.find((item) => item.id === id);
        let sizeText = selectedSizeOption?.value;
        sizeText = sizeText?.trim().substring(2).trim();
        switch (selectedSizeOption?.id) {
            case lcvSizeOptionsEnum.FT_8:
                size = 8;
                break;

            case lcvSizeOptionsEnum.FT_10:
                size = 10;
                break;

            case lcvSizeOptionsEnum.FT_14:
                size = 14;
                break;

            case lcvSizeOptionsEnum.FT_17:
                size = 17;
                break;

            case lcvSizeOptionsEnum.FT_19:
                size = 19;
                break;

            case lcvSizeOptionsEnum.FT_20:
                size = 20;
                break;

            case lcvSizeOptionsEnum.FT_22:
                size = 22;
                break;
        }
        setTimeout(() => {
            formik.setFieldValue(commonLorryFormFieldNames.lorrySize, size);
            formik.setFieldValue(commonLorryFormFieldNames.sizeText, sizeText);
        }, 0);

        if (formik.values.isLorryCapacityRequired) {
            scrollToElement(capacityDivRef);
            scrollToElement(rcUploadDivRef);
        }
    };

    const lorryCapacityText = NEW_LORRY_TYPES.filter(
        (x) => x.id === formik.values.lorryType
    )[0].bodyTypeWithCapacity?.find((x) => x.id === (selectedSize as any))?.capacityText;

    const lorryCapacity = lorryFormikValues.lorryCapacity;

    let availableBodySize = lcvSizeOptions;
    // let availableBodySize =
    //     lorryCapacity && yesThisIsMyVehicle
    //         ? lcvBodyClassification(lcvSizeOptions, sizeCapacityMapping, lorryCapacity)
    //         : !isLorryPosting && lorryCapacity
    //         ? lcvBodyClassification(lcvSizeOptions, sizeCapacityMapping, lorryCapacity)
    //         : lcvSizeOptions;

    const selectedLorryType = NEW_LORRY_TYPES.filter((x) => x.id === formik.values.lorryType)[0];

    const isLorryTyreSelectionAvailable = isBodyTypeSelected;
    let availableTyreOptions =
        selectedLorryType.tyreWithCapacity &&
        lcvBodyClassification(selectedLorryType.tyreWithCapacity, tyreCapacityMapping, lorryCapacity);
    const isTyreCountSelected = !!formik.values.lorryTyres;

    const isBodyTypeSelectionAvailable = Boolean(formik.values.lorryCapacity);
    const isBodySizeSelectionAvailable = formik.values.lorryTyres === 4 && isBodyTypeSelected && isTyreCountSelected;
    const isBodySizeSelected = isBodySizeSelectionAvailable && !!formik.values.lorrySize;

    const isRCUploadAvailable =
        isLorryPosting &&
        (isBodySizeSelectionAvailable ? isBodySizeSelected : isTyreCountSelected) &&
        Boolean(formik.values.lorryCapacity) &&
        (showUploadRc || !yesThisIsMyVehicle) &&
        !formik.errors.lorryCapacity &&
        !isEditModal;

    useEffect(() => {
        if (
            formik.values.lorryBodyType === LORRY_BODY_TYPE.OPEN_BODY ||
            formik.values.lorryBodyType === LORRY_BODY_TYPE.CLOSE_BODY
        ) {
            setIsBodyTypeSelected(true);
        } else {
            setIsBodyTypeSelected(false);
        }
    }, [formik.values.lorryBodyType, formik.values.lorryType]);

    useEffect(() => {
        scrollToElement(bodyTypeDivRef);
    }, []);

    return (
        <div>
            {isBodyTypeSelectionAvailable && (
                <div ref={bodyTypeDivRef}>
                    <div className={Styles.sectionHeading}>
                        <Typography mSize="sm" required weight="semibold">
                            {t("lorryPosting.select_lcv_body")}
                        </Typography>
                    </div>
                    <QuickFilterPillGroup
                        options={LcvBodyOptions}
                        selected={formik.values.lorryBodyType}
                        onFilterChange={handleBodyTypeFilterChange}
                        id={IDs.postingForm.selectType.body}
                    />
                </div>
            )}

            {isLorryTyreSelectionAvailable && availableTyreOptions && (
                <div ref={tyresDivRef}>
                    <div className={Styles.sectionHeading}>
                        <Typography mSize="sm" required weight="semibold">
                            {t("lorryPosting.select_tyre")}
                        </Typography>
                    </div>
                    <QuickFilterPillGroup
                        options={availableTyreOptions}
                        selected={formik.values.lorryTyres}
                        onFilterChange={handleTyreSelection}
                        id={IDs.postingForm.selectType.tyres}
                    />
                </div>
            )}

            {isBodySizeSelectionAvailable && (
                <div ref={sizeSelectionDivRef}>
                    <div className={Styles.sectionHeading}>
                        <Typography mSize="sm" required weight="semibold">
                            {t("lorryPosting.select_lcv_feet")}
                        </Typography>
                    </div>
                    <QuickFilterPillGroup
                        options={availableBodySize}
                        selected={selectedSize}
                        onFilterChange={handleSizeChange}
                        id={IDs.postingForm.selectType.feet}
                    />
                </div>
            )}
            {isRCUploadAvailable ? (
                <div className={Styles.uploadRcWrapper} ref={rcUploadDivRef}>
                    <UploadRc formik={formik} />
                </div>
            ) : null}
        </div>
    );
};
export default LcvFormFields;
