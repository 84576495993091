import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";

// Styles
import Styles from "./TankerFormFields.module.scss";

// Components
import QuickFilterPillGroup from "@vahak/core/dist/components/quick-filter-pill-group/QuickFilterPillGroup";

// Types
import {
    commonLorryFormFieldNames,
    editBulkLoadLorryDetails,
    loadCreationFormFieldsTypes,
    lorryCreationFormFieldsTypes
} from "../../../helpers/constants";
import { GA4EventNames, LoadPostingFormEvents } from "@vahak/core/dist/constants/event-names";
import { LORRY_BODY_TYPE, NEW_LORRY_TYPES } from "@vahak/core/dist/constants/lorryTypes";
import { IDs } from "@vahak/core/dist/constants/automation";

// Helpers
import { scrollToElement } from "@vahak/core/dist/methods/scrollToElement";
import UploadRc from "../upload-rc/UploadRc";
import Typography from "@vahak/core-ui/dist/components/Typography";
import { useEventTrackerService } from "@vahak/core/dist/_services/hooks/useEventTrackerService";
import { TruckBodyOptions } from "../truck/TruckFormFields";
import CapacityField from "../capacity-field/CapacityField";
import { tankerTyreClassification } from "./tankerClassificationHelper";
import { GenericObject } from "@vahak/core/dist/custom-types/generic-object";

interface TankerFormFieldsProps {
    formik: FormikProps<lorryCreationFormFieldsTypes | loadCreationFormFieldsTypes | editBulkLoadLorryDetails>;
    yesThisIsMyVehicle?: boolean;
    isLorryPosting: boolean;
    trackLoadFormEvent?: (name: string, data: GenericObject) => void;
    showUploadRc?: boolean;
}

const capacityOptions = [
    { id: 10, value: "10 KL" },
    { id: 25, value: "25 KL" },
    { id: 35, value: "35 KL" }
];

export const TankerBodyOptions = [
    { id: LORRY_BODY_TYPE.METAL_STEEL, value: "Metal Steel" },
    { id: LORRY_BODY_TYPE.STAINLESS_STEEL, value: "Stainless Steel" }
];

/**
 * This is important when we create lorry with api data
 * to filter tyre list based on capacity
 */

const tyreCapacityMapping = {
    0: {
        start: 0,
        end: -1 // Empty Array will be created
    },
    6: {
        start: 1,
        end: 10
    },
    10: {
        start: 11,
        end: 20
    },
    12: {
        start: 21,
        end: 50
    }
};

const TankerFormFields = ({
    formik,
    yesThisIsMyVehicle,
    isLorryPosting,
    trackLoadFormEvent,
    showUploadRc
}: TankerFormFieldsProps) => {
    const { t } = useTranslation(["common"]);

    const [isBodyTypeSelected, setIsBodyTypeSelected] = useState(false);
    const [isTyreCountSelected, setIsTyreCountSelected] = useState(false);

    const tyresDivRef = useRef<HTMLDivElement>(null);
    const capacityDivRef = useRef<HTMLDivElement>(null);
    const rcUploadDivRef = useRef<HTMLDivElement>(null);

    const { values: lorryFormikValues } = formik as FormikProps<lorryCreationFormFieldsTypes>;
    const { values: loadFormikValues } = formik as FormikProps<loadCreationFormFieldsTypes>;
    const isEditModal = lorryFormikValues?.formType === "edit";

    const selectedLorryType = NEW_LORRY_TYPES.filter((x) => x.id === formik.values.lorryType)[0];

    //Events
    const { sendGAandMoEngageEvent } = useEventTrackerService();

    const handleBodyChange = (id: number) => {
        formik.setFieldValue(commonLorryFormFieldNames.lorryBodyType, id);
        setIsBodyTypeSelected(true);
        scrollToElement(tyresDivRef);
        scrollToElement(rcUploadDivRef);

        /**
         * Events
         */

        if (isLorryPosting) {
            sendGAandMoEngageEvent({
                name: GA4EventNames.LORRY_POSTING_MODAL.lorry_form_body_type_selection,
                data: {
                    vehicle_number: lorryFormikValues.lorryNum,
                    lorry_type: lorryFormikValues.lorryType,
                    current_city: lorryFormikValues.currentCityText?.split(",")[0],
                    total_route: lorryFormikValues.routeIds.length,
                    routes: lorryFormikValues.routeIds,
                    vehicle_type: NEW_LORRY_TYPES.find((val) => val.id === lorryFormikValues.lorryType)?.label,
                    capacity: lorryFormikValues.lorryCapacity,
                    body_type: TankerBodyOptions.find((x) => x.id === id)?.value
                }
            });
        } else {
            trackLoadFormEvent?.(LoadPostingFormEvents["load_form_body_type_selection"], {
                body_type: TruckBodyOptions.find((x) => x.id === id)
                    ?.value?.toLowerCase()
                    .replaceAll(" ", "_")
            });
        }
    };

    const handleTyreSelection = (value: number) => {
        // Resetting the after formik state

        let obj = {
            [commonLorryFormFieldNames.lorryTyres]: value,
            [commonLorryFormFieldNames.lorrySize]: 0
        };

        formik.setValues({
            ...formik.values,
            ...obj
        });
        setIsTyreCountSelected(true);
        scrollToElement(capacityDivRef);

        /**
         * Events
         */

        if (isLorryPosting) {
            sendGAandMoEngageEvent({
                name: GA4EventNames.LORRY_POSTING_MODAL.lorry_form_tyres_selection,
                data: {
                    vehicle_number: lorryFormikValues?.lorryNum,
                    lorry_type: lorryFormikValues?.lorryType,
                    current_city: lorryFormikValues?.currentCityText?.split(",")[0],
                    total_route: lorryFormikValues?.routeIds?.length,
                    routes: lorryFormikValues?.routeIds,
                    vehicle_type: NEW_LORRY_TYPES.find((val) => val.id === lorryFormikValues?.lorryType)?.label,
                    capacity: lorryFormikValues?.lorryCapacity,
                    body_type: TruckBodyOptions.find((x) => x.id === lorryFormikValues.lorryBodyType)?.value,
                    total_tyres: value
                }
            });
        } else {
            trackLoadFormEvent?.(LoadPostingFormEvents["load_form_tyres_selection"], {
                required_tyres: value
            });
        }
    };

    // let availableTyreOptions = selectedLorryType.tyreWithCapacity;
    const lorryCapacity = formik.values.lorryCapacity;

    let availableTyreOptions =
        selectedLorryType.tyreWithCapacity &&
        tankerTyreClassification(selectedLorryType.tyreWithCapacity, tyreCapacityMapping, lorryCapacity);

    /**
     * Capacity based on tyres
     */
    const selectedLorryTyreAndCapacity = selectedLorryType.tyreWithCapacity?.find(
        (x) => x.id === formik.values.lorryTyres
    );

    const isBodySizeSelectionAvailable = Boolean(formik.values.lorryType);
    const isTyreSelectionAvailable = isBodyTypeSelected;

    const isRCUploadAvailable =
        isLorryPosting && Boolean(formik.values.lorryCapacity) && (showUploadRc || !yesThisIsMyVehicle) && !isEditModal;

    return (
        <div>
            {isBodySizeSelectionAvailable ? (
                <>
                    <div className={Styles.sectionHeading}>
                        <Typography mSize="sm" required weight="semibold">
                            {t("lorryPosting.select_tanker_body")}
                        </Typography>
                    </div>
                    <QuickFilterPillGroup
                        options={TankerBodyOptions}
                        selected={formik.values.lorryBodyType}
                        onFilterChange={handleBodyChange}
                        id={IDs.postingForm.selectType.body}
                    />
                </>
            ) : (
                <></>
            )}

            {isTyreSelectionAvailable && availableTyreOptions && (
                <div ref={tyresDivRef}>
                    <div className={Styles.sectionHeading}>
                        <Typography mSize="sm" required weight="semibold">
                            {t("lorryPosting.select_tyre")}
                        </Typography>
                    </div>
                    <QuickFilterPillGroup
                        options={availableTyreOptions}
                        selected={formik.values.lorryTyres}
                        onFilterChange={handleTyreSelection}
                        id={IDs.postingForm.selectType.tyres}
                    />
                </div>
            )}

            {isRCUploadAvailable ? (
                <div ref={rcUploadDivRef}>
                    <UploadRc formik={formik} />
                </div>
            ) : null}
        </div>
    );
};

export default TankerFormFields;
