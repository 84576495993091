import { lcvSizeOptionsEnum } from "./LcvFormFields";

const filterObject = (obj: any, capacity: number) => {
    const filteredObj: any = {};

    for (const key in obj) {
        const { start, end } = obj[key];

        if (capacity >= start && capacity <= end) {
            filteredObj[key] = obj[key];
        }
    }

    return filteredObj;
};

export const lcvBodyClassification = (
    tyresOptions: {
        id: number;
        value: string;
    }[],
    tyreCapacityMapping: {
        [key: number]: {
            start: number;
            end: number;
        };
    },
    lorryCapacity: number
) => {
    const qualifiedOption = filterObject(tyreCapacityMapping, Math.ceil(lorryCapacity));
    return tyresOptions.filter((x) => Object.prototype.hasOwnProperty.call(qualifiedOption, x.id));
};

export const getLcvSizeEnum = (size: number) => {
    if (size === 8) return lcvSizeOptionsEnum.FT_8;
    else if (size === 10) return lcvSizeOptionsEnum.FT_10;
    else if (size === 14) return lcvSizeOptionsEnum.FT_14;
    else if (size === 17) return lcvSizeOptionsEnum.FT_17;
    else if (size === 19) return lcvSizeOptionsEnum.FT_19;
    else return null;
};
